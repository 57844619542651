
import { defineComponent } from "vue";
import RouteTabs from "../components/RouteTabs.vue";

export default defineComponent({
  name: "App",
  props: {},
  setup() {
    
    return {};
  },
  components: {
    RouteTabs,
  },
});
