import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_tabs, {
      class: "route-tabs",
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      onTabClick: _ctx.tabClick
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subRoutes, (route, index) => {
          return (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: index,
            tab: route.meta.title
          }, null, 8, ["tab"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeKey", "onTabClick"]),
    _createVNode(_component_router_view)
  ], 64))
}